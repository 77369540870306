import React from 'react';

export default function footer() {
    return (
      <div className="footer">
        <div className="social-media">
          <a href="https://www.facebook.com/AMGuitarra/" className="social-link" target="_blank" rel="noreferrer"><i aria-label={'Facebook link'} className="fa fa-facebook fa-lg"/>AMGuitarra</a>
          <a href="https://www.youtube.com/channel/UCI9C9pTAhOKjdtMpb8nn-8g" className="social-link" target="_blank" rel="noreferrer"><i aria-label={'Youtube link'} className="fa fa-youtube fa-lg"/>AMGuitarra</a>
        </div>
        &copy; Asociación Mexciana de Guitarra Clásica y Popular 2020 - {new Date().getFullYear()}
      </div>
    );
}
